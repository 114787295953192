/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import Link from '../Link';
import * as S from './styles';

type Props = {
    children: React.Node,
    to?: string
};

const CTABlock = ({children, to = ''}: Props) => (
    <Link to={to} css={S.button}>
        <S.Wrapper>
            {children}
            <S.Icon width="29" height="19" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.184 8.878h25.169l-7.82-7.82.836-.838 8.37 8.37.837.837-9.207 9.207-.837-.837 7.736-7.736H0l1.184-1.183z"
                    fillRule="nonzero"
                />
            </S.Icon>
        </S.Wrapper>
    </Link>
);

export default CTABlock;
