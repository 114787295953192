/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {breakpoints, colors, spacing} from '../../globals/variables';

export const Container = styled.section`
    position: relative;
`;

export const contentContainer = css`
    margin-bottom: ${responsiveSpacing(spacing.s)};

    @media (min-width: ${rem(breakpoints.mobile)}) {
        display: flex;
    }
`;

export const allLink = css`
    color: ${colors.white};
    float: right;
`;
