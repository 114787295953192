/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {colors, spacing} from '../../globals/variables';

export const Container = styled.div`
    background-color: ${colors.greyLight};
    color: ${colors.black};
    min-height: 100%;
    padding: ${responsiveSpacing(spacing.m)};
    position: relative;

    &::before {
        background: linear-gradient(330deg, ${colors.greyLight} 92.5%, rgba(0, 0, 0, 0) 92.5%);
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: -6px;
        width: 100%;
        z-index: 0;
    }
`;

export const heading = css`
    color: ${colors.grey};
`;
