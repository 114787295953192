/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {between, rem, shade} from 'polished';
import {
    breakpoints,
    colors,
    fontFamilies,
    fontSizes,
    spacing,
    themes,
    transitions
} from '../../globals/variables';

export const button = css`
    align-items: center;
    border-radius: 0;
    border: 0;
    cursor: pointer;
    display: flex;
    font-family: ${fontFamilies.bold};
    font-size: 1.2rem;
    height: 100%;
    line-height: 1.4;
    overflow: visible;
    position: relative;
    text-align: left;
    transition: ${transitions.default};
    width: 100%;

    &::before {
        background: linear-gradient(330deg, ${themes.light.accent} 40%, rgba(0, 0, 0, 0) 40%);
        bottom: -8px;
        content: '';
        height: 100%;
        right: -8px;
        position: absolute;
        transition: ${transitions.default};
        width: 100%;
        z-index: 0;
    }

    &:hover,
    &:active {
        transform: translateY(-2px);
    }
    &:hover::before,
    &:active::before {
        transform: translateX(4px) translateY(4px);
    }

    @media (min-width: ${rem(breakpoints.tablet)}) {
        font-size: ${between(`${fontSizes.h5}px`, `${fontSizes.h5 + 4}px`)};
    }
`;

const blockPadding = (size: string = 'm') =>
    between(`${spacing[size] + 4}px`, `${spacing[size] + 10}px`);

export const Wrapper = styled.span`
    background-color: ${themes.light.background};
    color: ${themes.light.text};
    cursor: pointer;
    display: block;
    height: 100%;
    padding: ${blockPadding()};
    position: relative;
    transition: ${transitions.default};
    width: 100%;
    z-index: 1;

    a:hover &,
    a:active &,
    button:hover &,
    button:active & {
        background-color: ${shade(0.015, themes.light.background)};
    }

    @media (min-width: ${rem(breakpoints.tablet)}) {
        align-items: center;
        display: flex;
        padding-right: ${blockPadding('l')};
    }
`;

export const Icon = styled.svg`
    bottom: ${rem(spacing.s)};
    fill: ${colors.grey};
    position: absolute;
    right: ${rem(spacing.s)};
    transition: ${transitions.default};
    transform: translateY(-50%);

    a:hover &,
    a:active &,
    button:hover &,
    button:active & {
        fill: ${colors.orange};
        transform: translateY(-50%) scale(1.15);
    }

    @media (min-width: ${rem(breakpoints.tablet)}) {
        bottom: auto;
        right: ${blockPadding('s')};
        top: 50%;
    }
`;
