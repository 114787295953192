/**
 * @prettier
 */
import React from 'react';
import {graphql, Link} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import AboutHero from '../components/AboutHero';
import Container from '../components/Container';
import ResourcesHero from '../components/ResourcesHero';
import HeroBanner from '../components/HeroBanner';
import Page from '../components/Page';
import ServicesHero from '../components/ServicesHero';
import SEO from '../components/SEO';
import Theme from '../components/Theme';
import * as UI from '../components/UI/styles';
import {getResources} from '../globals/functions';

const IndexPage = ({data}) => {
    const page = get(data, 'page');
    const resources = get(data, 'resources');
    const aboutTeaser = getResources(page, 'About teaser');
    const servicesTeaser = getResources(page, 'Services teaser');
    const resourcesTeaser = getResources(page, 'Guides teaser');

    return (
        <Page>
            <SEO
                title={page.metaTitle || page.pageHeading}
                description={page.metaDescription}
                image={get(page, 'metaImage.fixed.src', '')}
            />
            <HeroBanner title={page.pageHeading} />

            <UI.ResponsiveSpacer size="s" sizeAtMobile="l" />

            <Container>
                <UI.Columns>
                    <ReactMarkdown source={get(page, 'content.content')} />
                </UI.Columns>

                <UI.ResponsiveSpacer size="l" />

                <UI.LayoutContainer stack css={UI.flexAtMobile}>
                    <UI.LayoutItem sizeAtMobile={6 / 12}>
                        <ServicesHero {...servicesTeaser} />
                    </UI.LayoutItem>
                    <UI.LayoutItem sizeAtMobile={6 / 12}>
                        <AboutHero {...aboutTeaser} />
                    </UI.LayoutItem>
                </UI.LayoutContainer>
            </Container>

            <UI.ResponsiveSpacer size="l" />

            <Theme theme="primary">
                <UI.ResponsiveSpacer size="l" />
                <Container verticalPadding={false}>
                    <ResourcesHero {...resourcesTeaser} resources={resources} />
                </Container>
                <UI.ResponsiveSpacer size="l" />
            </Theme>
        </Page>
    );
};

export const query = graphql`
    query {
        page: contentfulPage(title: {eq: "Home"}) {
            pageHeading
            content {
                content
            }
            resources {
                title
                references {
                    key
                    value {
                        value
                    }
                }
            }
            metaTitle
            metaDescription
            metaImage {
                fixed(width: 1000) {
                    src
                }
            }
        }
        resources: allContentfulPage(
            filter: {pageType: {eq: "Resource"}}
            sort: {fields: [publishDate], order: DESC}
            limit: 2
        ) {
            edges {
                node {
                    title
                    slug
                }
            }
        }
    }
`;

export default IndexPage;
