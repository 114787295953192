/**
 * @prettier
 * @flow
 */
import React from 'react';
import Helmet from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';

type Props = {
    description?: string,
    lang?: string,
    image?: string,
    meta?: Array<{
        property: string,
        content: string
    }>,
    title?: string
};

const SEO = ({description = '', lang = 'en', image = '', meta = [], title = ''}: Props) => (
    <StaticQuery
        query={detailsQuery}
        render={data => {
            const metaDescription = description || data.site.siteMetadata.description;

            return (
                <Helmet
                    htmlAttributes={{
                        lang
                    }}
                    title={title}
                    titleTemplate={`%s | ${data.site.siteMetadata.title}`}
                    meta={[
                        {
                            name: 'description',
                            content: metaDescription
                        },
                        {
                            property: 'og:title',
                            content: title
                        },
                        {
                            property: 'og:description',
                            content: metaDescription
                        },
                        {
                            property: 'og:image',
                            content: image
                        },
                        {
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            name: 'twitter:card',
                            content: 'summary'
                        },
                        {
                            name: 'twitter:creator',
                            content: data.site.siteMetadata.author
                        },
                        {
                            name: 'twitter:title',
                            content: title
                        },
                        {
                            name: 'twitter:description',
                            content: metaDescription
                        }
                    ]}
                />
            );
        }}
    />
);

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`;
