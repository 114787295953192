/**
 * @prettier
 * @flow
 */
import React from 'react';
import {Link} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import CTABlock from '../CTABlock';
import * as S from './styles';
import * as UI from '../UI/styles';

type Props = {
    resources?: {
        edges: Array<{
            node: {
                slug: string,
                title: string
            }
        }>
    },
    cta_label: string,
    text: string,
    title: string
};

const ResourcesHero = ({cta_label = 'View all', resources = {}, text, title}: Props) => (
    <S.Container>
        <UI.LayoutContainer css={S.contentContainer}>
            <UI.LayoutItem sizeAtMobile={4 / 12}>
                <UI.Heading3>{title}</UI.Heading3>
            </UI.LayoutItem>
            <UI.LayoutItem sizeAtMobile={8 / 12}>
                <ReactMarkdown source={text} />
            </UI.LayoutItem>
        </UI.LayoutContainer>

        <UI.Spacer size="m" sizeAtMobile="none" />

        {resources.edges && (
            <UI.LayoutContainer css={UI.flexAtMobile}>
                {resources.edges.map(guide => (
                    <UI.LayoutItem sizeAtMobile={6 / 12} key={guide.node.slug}>
                        <CTABlock to={`/resources/${guide.node.slug}`}>{guide.node.title}</CTABlock>
                        <UI.Spacer size="m" />
                    </UI.LayoutItem>
                ))}
            </UI.LayoutContainer>
        )}

        <UI.Spacer />
        <Link to="/resources" css={S.allLink}>
            {cta_label}
        </Link>
    </S.Container>
);

export default ResourcesHero;
