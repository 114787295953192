/**
 * @prettier
 * @flow
 */
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import * as S from './styles';
import * as UI from '../UI/styles';

type Props = {
    subtitle: string,
    text: string,
    title: string
};

const ServicesHero = ({subtitle, text, title}: Props) => (
    <S.Container>
        <UI.Heading3 css={S.heading}>{title}</UI.Heading3>

        <S.Label>{subtitle}</S.Label>
        <S.TextContainer>
            <ReactMarkdown source={text} />
        </S.TextContainer>
    </S.Container>
);

export default ServicesHero;
