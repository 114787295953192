/**
 * @prettier
 * @flow
 */
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {rem} from 'polished';
import {responsiveSpacing} from '../../globals/functions';
import {colors, spacing} from '../../globals/variables';

export const Container = styled.div`
    background-color: ${colors.greyDark};
    color: ${colors.white};
    min-height: 100%;
    padding: ${responsiveSpacing(spacing.m)};
    position: relative;

    &::before {
        background: linear-gradient(330deg, ${colors.greyDark} 92.5%, rgba(0, 0, 0, 0) 92.5%);
        content: '';
        height: 6px;
        left: 0%;
        position: absolute;
        top: -6px;
        width: 100%;
        z-index: 0;
    }
`;

export const Label = styled.span`
    color: ${colors.grey};
    font-size: ${rem(15)};
    letter-spacing: ${rem(2)};
    text-transform: uppercase;
`;

export const ListContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        margin-bottom: ${responsiveSpacing(spacing.m)};
    }
`;

export const heading = css`
    margin-bottom: ${rem(spacing.xs)};
`;

export const TextContainer = styled.div`
    h3 {
        margin-bottom: 0;
    }
`;
